/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
// Material Dashboard 2 React context
import { setLayout, useMaterialUIController } from "context"
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"
import { useEffect } from "react"
// react-router-dom components
import { useLocation } from "react-router-dom"

function DashboardLayout({ children }) {
	const [controller, dispatch] = useMaterialUIController()
	const { miniSidenav } = controller
	const { pathname } = useLocation()

	useEffect(() => {
		setLayout(dispatch, "dashboard")
	}, [pathname])

	return (
		<MDBox
			sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
				p: 3,
				position: "relative",

				[breakpoints.up("xl")]: {
					marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
					transition: transitions.create(["margin-left", "margin-right"], {
						easing: transitions.easing.easeInOut,
						duration: transitions.duration.standard,
					}),
				},
				minHeight: "100vh",
			})}
		>
			{children}
		</MDBox>
	)
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default DashboardLayout
