import { Card, Grid } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { isEmpty, map } from "lodash"
import PropTypes from "prop-types"

import PrayItem from "./PrayItem"

const PraysList = ({ data = {}, parish }) => {
	return (
		<MDBox py={8} flex={1}>
			{isEmpty(data) ? (
				<Grid container spacing={4} flex={1}>
					<Grid item xs={12} mt={2} flex={1} textAlign="center">
						<MDTypography variant="h5" color="text">
							{parish ? "등록된 기도제목이 없습니다." : "교구를 선택해 주세요."}
						</MDTypography>
					</Grid>
				</Grid>
			) : (
				map(data, ({ group_id, group_name, prayer_lists = [] }) => (
					<Grid container spacing={1} key={group_id} mb={5}>
						<Grid item xs={12} p={{ xs: 0, lg: 2, xl: 3 }}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
								>
									<MDTypography variant="h6" color="white">
										{`${group_name} 기도제목`}
									</MDTypography>
								</MDBox>
								<Grid
									container
									spacing={3}
									p={{ xs: 1, lg: 2, xl: 3 }}
									mt={{ xs: 2, md: 1, lg: 0 }}
								>
									{map(prayer_lists, (pray) => (
										<PrayItem key={pray.user_id} pray={pray} groupId={group_id} />
									))}
								</Grid>
							</Card>
						</Grid>
					</Grid>
				))
			)}
		</MDBox>
	)
}

export default PraysList

PraysList.propTypes = {
	data: PropTypes.object.isRequired,
	parish: PropTypes.string,
}
