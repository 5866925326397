/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card"
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDInput from "components/MDInput"
import MDTypography from "components/MDTypography"
import { DB_COLECTION } from "constant"
// Firebase authentication methods
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth"
import { collection, getDocs, query, where } from "firebase/firestore"
import useFirebaseApp from "hooks/useFirebaseApp"
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout"
import { isEmpty } from "lodash"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { openSnackbar } from "stores/slice/snackbar"
import { storeUser } from "stores/slice/user"
import { ROUTE_ATEENDANCE } from "urls"

const ALLOWED_TYPES = ["목회자", "교구장", "지구장"]

function Basic() {
	const [isFetching, setIsFetching] = useState(false)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { getFirestoreInstance } = useFirebaseApp()

	const {
		register,
		handleSubmit,
		setError,
		getValues,
		formState: { errors },
		clearErrors,
	} = useForm()

	const onClickResetPwd = () => {
		const auth = getAuth()
		const email = getValues("email")
		if (isEmpty(email)) {
			setError("email", { message: "이메일을 입력해 주세요." })
			return
		}

		setIsFetching(true)
		sendPasswordResetEmail(auth, email)
			.then(() => {
				dispatch(
					openSnackbar({ severity: "info", message: "비밀번호 재설정 이메일을 발송했습니다." })
				)
			})
			.catch((error) => {
				const errorMsg = error.message
				dispatch(
					openSnackbar({ severity: "error", message: "해당 이메일로 가입된 계정이 없습니다." })
				)
			})
			.finally(() => {
				setIsFetching(false)
			})
	}

	const onSubmit = (data) => {
		const { email, password } = data
		if (isEmpty(email)) {
			setError("email", { message: "이메일을 입력해 주세요." })
			return
		} else if (isEmpty(password)) {
			setError("password", { message: "비밀번호를 입력해 주세요." })
			return
		}

		const auth = getAuth()
		const firestore = getFirestoreInstance()

		setIsFetching(true)
		signInWithEmailAndPassword(auth, email, password)
			.then(async (userCredential) => {
				const { uid } = userCredential.user

				const q = query(collection(firestore, DB_COLECTION.USER), where("authId", "==", uid))
				const querySnapshot = await getDocs(q)
				if (querySnapshot.empty) {
					dispatch(
						openSnackbar({ severity: "error", message: "해당 이메일로 가입된 계정이 없습니다." })
					)
					return
				}

				const uuid = querySnapshot.docs.map((doc) => doc.id)[0]
				const user = querySnapshot.docs.map((doc) => doc.data())[0]
				const { display_name, ddorae, group, parish, phone_number, type, email } = user

				if (!ALLOWED_TYPES.includes(type)) {
					dispatch(
						openSnackbar({
							severity: "error",
							message: "로그인 권한이 없습니다. 관리자에게 문의하세요.",
						})
					)
					return
				}

				dispatch(
					storeUser({
						id: uuid,
						email,
						name: display_name,
						type,
						ddorae,
						group,
						parish,
						phone_number,
					})
				)

				navigate(ROUTE_ATEENDANCE, { replace: true })
			})
			.catch((error) => {
				const errorMsg = error.message
				dispatch(openSnackbar({ severity: "error", message: errorMsg }))
			})
			.finally(() => {
				setIsFetching(false)
			})
	}

	return (
		<BasicLayout image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" my={1}>
						로그인
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput
								type="email"
								label="이메일"
								fullWidth
								{...register("email")}
								onFocus={() => clearErrors("email")}
								onKeyDown={(e) => e.key === "Enter" && handleSubmit(onSubmit)()}
							/>
						</MDBox>
						<MDBox>
							<MDInput
								type="password"
								label="비밀번호"
								fullWidth
								{...register("password")}
								onFocus={() => clearErrors("password")}
								onKeyDown={(e) => e.key === "Enter" && handleSubmit(onSubmit)()}
							/>
						</MDBox>
						{errors.email && (
							<MDBox>
								<MDTypography variant="caption" color="error" fontWeight="regular">
									{errors.email.message}
								</MDTypography>
							</MDBox>
						)}
						{errors.password && (
							<MDBox>
								<MDTypography variant="caption" color="error" fontWeight="regular">
									{errors.password.message}
								</MDTypography>
							</MDBox>
						)}
						<MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={1}>
							<MDButton variant="text" color="info" size="small" onClick={onClickResetPwd}>
								비밀번호 재설정
							</MDButton>
						</MDBox>
						<MDBox mt={1} mb={1}>
							<MDButton
								variant="gradient"
								color="info"
								fullWidth
								onClick={handleSubmit(onSubmit)}
								disabled={isFetching}
							>
								로그인
							</MDButton>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</BasicLayout>
	)
}

export default Basic
