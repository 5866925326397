import { createSlice } from "@reduxjs/toolkit"
import { SNACKBAR_SLICE } from "stores/sliceKeys"

const initialState = {
	open: false,
	message: "",
	severity: "success",
	delay: 3000,
}

const snackbarSlice = createSlice({
	name: SNACKBAR_SLICE,
	initialState,
	reducers: {
		openSnackbar(state, action) {
			const { message, severity } = action.payload
			state.open = true
			state.message = message
			state.severity = severity
		},
		closeSnackbar(state) {
			state.open = false
		},
	},
})

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions
export default snackbarSlice.reducer
