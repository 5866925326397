/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
// @mui material components
import Icon from "@mui/material/Icon"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
// Material Dashboard 2 React context
import { useMaterialUIController } from "context"
import PropTypes from "prop-types"

function Bill({ name, company, email, vat, noGutter }) {
	const [controller] = useMaterialUIController()
	const { darkMode } = controller

	return (
		<MDBox
			component="li"
			display="flex"
			justifyContent="space-between"
			alignItems="flex-start"
			bgColor={darkMode ? "transparent" : "grey-100"}
			borderRadius="lg"
			p={3}
			mb={noGutter ? 0 : 1}
			mt={2}
		>
			<MDBox width="100%" display="flex" flexDirection="column">
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems={{ xs: "flex-start", sm: "center" }}
					flexDirection={{ xs: "column", sm: "row" }}
					mb={2}
				>
					<MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
						{name}
					</MDTypography>

					<MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
						<MDBox mr={1}>
							<MDButton variant="text" color="error">
								<Icon>delete</Icon>&nbsp;delete
							</MDButton>
						</MDBox>
						<MDButton variant="text" color={darkMode ? "white" : "dark"}>
							<Icon>edit</Icon>&nbsp;edit
						</MDButton>
					</MDBox>
				</MDBox>
				<MDBox mb={1} lineHeight={0}>
					<MDTypography variant="caption" color="text">
						Company Name:&nbsp;&nbsp;&nbsp;
						<MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
							{company}
						</MDTypography>
					</MDTypography>
				</MDBox>
				<MDBox mb={1} lineHeight={0}>
					<MDTypography variant="caption" color="text">
						Email Address:&nbsp;&nbsp;&nbsp;
						<MDTypography variant="caption" fontWeight="medium">
							{email}
						</MDTypography>
					</MDTypography>
				</MDBox>
				<MDTypography variant="caption" color="text">
					VAT Number:&nbsp;&nbsp;&nbsp;
					<MDTypography variant="caption" fontWeight="medium">
						{vat}
					</MDTypography>
				</MDTypography>
			</MDBox>
		</MDBox>
	)
}

// Setting default values for the props of Bill
Bill.defaultProps = {
	noGutter: false,
}

// Typechecking props for the Bill
Bill.propTypes = {
	name: PropTypes.string.isRequired,
	company: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	vat: PropTypes.string.isRequired,
	noGutter: PropTypes.bool,
}

export default Bill
