/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React helper functions
import borders from "assets/theme-dark/base/borders"
import boxShadows from "assets/theme-dark/base/boxShadows"
// Material Dashboard 2 React base styles
import colors from "assets/theme-dark/base/colors"
import pxToRem from "assets/theme-dark/functions/pxToRem"

const { transparent } = colors
const { md } = boxShadows
const { borderRadius } = borders

const popover = {
	styleOverrides: {
		paper: {
			backgroundColor: transparent.main,
			boxShadow: md,
			padding: pxToRem(8),
			borderRadius: borderRadius.md,
		},
	},
}

export default popover
