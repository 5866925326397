import { createSlice } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { USER_SLICE } from "stores/sliceKeys"

/**
 * @typedef {Object} User
 * @property {string} id
 * @property {string} email
 * @property {string} name
 * @property {string} type "admin" | "user"
 * @property {string} ddorae
 * @property {string} group
 * @property {string} parish
 * @property {string} phone_number
 */
const initialState = {
	id: null,
	email: "",
	name: "",
	type: "",
	ddorae: "",
	group: "",
	parish: "",
	phone_number: "",
}

const userSlice = createSlice({
	name: USER_SLICE,
	initialState,
	reducers: {
		storeUser(state, action) {
			const { id, email, name, type, ddorae, group, parish, phone_number } = action.payload
			return { ...state, id, email, name, type, ddorae, group, parish, phone_number }
		},
		removeUser() {
			return { ...initialState }
		},
	},
})

export const { storeUser, removeUser } = userSlice.actions

export const useUser = () => useSelector((state) => state[USER_SLICE])

export default userSlice.reducer
