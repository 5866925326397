/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React Base Styles
import borders from "assets/theme-dark/base/borders"
import boxShadows from "assets/theme-dark/base/boxShadows"
import colors from "assets/theme-dark/base/colors"
// Material Dashboard 2 React Helper Function
import rgba from "assets/theme-dark/functions/rgba"

const { black, background } = colors
const { borderWidth, borderRadius } = borders
const { md } = boxShadows

const card = {
	styleOverrides: {
		root: {
			display: "flex",
			flexDirection: "column",
			position: "relative",
			minWidth: 0,
			wordWrap: "break-word",
			backgroundImage: "none",
			backgroundColor: background.card,
			backgroundClip: "border-box",
			border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
			borderRadius: borderRadius.xl,
			boxShadow: md,
			overflow: "visible",
		},
	},
}

export default card
