import axios from "axios"

export const BASE_ADMIN_URL = "api"

export const customAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL}/${BASE_ADMIN_URL}`,
	withCredentials: true,
})

const UNAUTHORIZED_STATUSES = [419, 401, 403]

customAxios.interceptors.response.use(
	(response) => response,
	async (rawError) => {
		const response = rawError.response || {}

		if (UNAUTHORIZED_STATUSES.includes(response.status)) {
			// TODO: Add logic to handle unauthorized requests
		}

		throw response.data || rawError
	}
)

export default customAxios
