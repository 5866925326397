import { isEqual } from "lodash"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updateAppVersion, useAppVersion } from "stores/slice/appVersion"
import { openSnackbar } from "stores/slice/snackbar"
import { removeUser } from "stores/slice/user"

const ForcedAppVersionCheck = () => {
	const storedAppVersion = useAppVersion()
	const dispatch = useDispatch()

	useEffect(() => {
		const currentAppVersion = process.env.REACT_APP_VERSION
		if (!isEqual(storedAppVersion.appVersion, currentAppVersion)) {
			dispatch(
				openSnackbar({
					severity: "success",
					message:
						"홈페이지 업데이트로 인해 사용자의 세션이 초기화되었습니다. 다시 로그인 해주세요.",
				})
			)
			dispatch(updateAppVersion({ appVersion: currentAppVersion }))
			dispatch(removeUser())
		}
	}, [])
}

export default ForcedAppVersionCheck
