/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import PropTypes from "prop-types"

function DataTableBodyCell({ noBorder, align, children, noPadding }) {
	const padding = noPadding ? { pt: 0.5 } : { py: 1.5, px: 3 }
	return (
		<MDBox
			component="td"
			textAlign={align}
			{...padding}
			sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
				fontSize: size.sm,
				borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
			})}
		>
			<MDBox
				display="inline-block"
				width="max-content"
				color="text"
				sx={{ verticalAlign: "middle" }}
			>
				{children}
			</MDBox>
		</MDBox>
	)
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
	noBorder: false,
	align: "left",
	noPadding: false,
}

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
	children: PropTypes.node.isRequired,
	noBorder: PropTypes.bool,
	align: PropTypes.oneOf(["left", "right", "center"]),
	noPadding: PropTypes.bool,
}

export default DataTableBodyCell
