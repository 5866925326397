import { Alert, Snackbar, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeSnackbar } from "stores/slice/snackbar"
import { SNACKBAR_SLICE } from "stores/sliceKeys"

const CustomSnackbar = () => {
	const dispatch = useDispatch()
	const state = useSelector((state) => state[SNACKBAR_SLICE])

	const onClose = () => {
		dispatch(closeSnackbar())
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={state.open}
			onClose={onClose}
			autoHideDuration={state.delay}
		>
			<Alert severity={state.severity} onClose={onClose} variant="filled">
				<Typography fontSize={14} fontWeight={700}>
					{state.message}
				</Typography>
			</Alert>
		</Snackbar>
	)
}

export default CustomSnackbar
