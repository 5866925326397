import { Card, Grid, Icon } from "@mui/material"
import MDBox from "components/MDBox"
import { showModal } from "components/Modal"
import { useMaterialUIController } from "context"
import { UserDetail } from "layouts/user/components"
import PropTypes from "prop-types"
import { useState } from "react"

const PrayItem = ({ pray, groupId }) => {
	const [controller] = useMaterialUIController()
	const { darkMode } = controller

	const { user_id, prayers, profile_url, user_name } = pray

	const [profileUrl, setProfileUrl] = useState(profile_url)

	const onClickPrayItem = () => {
		showModal(({ closeModal }) => (
			<UserDetail
				userId={user_id}
				groupId={groupId}
				closeModal={closeModal}
				setProfileUrl={setProfileUrl}
			/>
		))
	}

	return (
		<Grid item xs={12} md={6} xl={4} key={user_id} py={1}>
			<Card
				sx={{ ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)", cursor: "pointer" } }}
				onClick={onClickPrayItem}
			>
				<MDBox
					variant="gradient"
					bgColor={darkMode ? "dark" : "white"}
					color={darkMode ? "white" : "dark"}
					coloredShadow={darkMode ? "dark" : "info"}
					borderRadius="xl"
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="5rem"
					height="5rem"
					mt={-3}
				>
					{profileUrl ? (
						<img
							src={profileUrl}
							alt={user_name}
							width="auto"
							height="100%"
							style={{ borderRadius: "0.75rem" }}
						/>
					) : (
						<Icon fontSize="large" color="inherit">
							{"person"}
						</Icon>
					)}
				</MDBox>
				<MDBox
					mx={2}
					py={3}
					display="flex"
					justifyContent="space-between"
					alignItems="flex-start"
					flexDirection="column"
					gap={3}
					position="relative"
				>
					<MDBox
						component="h2"
						fontWeight="medium"
						fontSize="18px"
						lineHeight="1.2"
						color={darkMode ? "white" : "text.main"}
						position="absolute"
						top="-2rem"
						right="1rem"
					>
						{user_name} 기도제목
					</MDBox>
					<MDBox component="h6" fontSize="16px" lineHeight="1.2" fontWeight="regular">
						{prayers.map(({ request_id, content }, index) => (
							<MDBox key={request_id} mb={1}>
								<MDBox
									component="p"
									fontSize="16px"
									lineHeight="1.2"
									color={darkMode ? "white" : "text.main"}
								>
									{`${index + 1}. ${content}`}
								</MDBox>
							</MDBox>
						))}
					</MDBox>
				</MDBox>
			</Card>
		</Grid>
	)
}

export default PrayItem

PrayItem.propTypes = {
	pray: PropTypes.object.isRequired,
	groupId: PropTypes.string,
}
