/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import CssBaseline from "@mui/material/CssBaseline"
import Icon from "@mui/material/Icon"
// @mui material components
import { ThemeProvider } from "@mui/material/styles"
import brandDark from "assets/images/logo-ct-dark.png"
// Images
import brandWhite from "assets/images/logo-ct.png"
// Material Dashboard 2 React themes
import theme from "assets/theme"
// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark"
import themeDarkRTL from "assets/theme-dark/theme-rtl"
import themeRTL from "assets/theme/theme-rtl"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import CustomSnackbar from "components/Snackbar"
// Material Dashboard 2 React contexts
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context"
import Configurator from "examples/Configurator"
// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav"
import SignIn from "layouts/authentication/sign-in"
import { useEffect, useMemo, useState } from "react"
// react-router components
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
// Material Dashboard 2 React routes
import routes from "routes"
import { useUser } from "stores/slice/user"
// RTL plugins
import rtlPlugin from "stylis-plugin-rtl"
import { ROUTE_ATEENDANCE, ROUTE_LOGIN } from "urls"

export default function App() {
	const [controller, dispatch] = useMaterialUIController()
	const {
		miniSidenav,
		direction,
		layout,
		openConfigurator,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller
	const [onMouseEnter, setOnMouseEnter] = useState(false)
	const [rtlCache, setRtlCache] = useState(null)
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const user = useUser()

	// Cache for the rtl
	useMemo(() => {
		const cacheRtl = createCache({
			key: "rtl",
			stylisPlugins: [rtlPlugin],
		})

		setRtlCache(cacheRtl)
	}, [])

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false)
			setOnMouseEnter(true)
		}
	}

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true)
			setOnMouseEnter(false)
		}
	}

	// Change the openConfigurator state
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction)
	}, [direction])

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0
		document.scrollingElement.scrollTop = 0
	}, [pathname])

	useEffect(() => {
		if (user.id === null) {
			return navigate(ROUTE_LOGIN)
		}

		if (pathname === "/") {
			return navigate(ROUTE_ATEENDANCE)
		}
	}, [pathname, user])

	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse)
			}

			if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key} />
			}

			return null
		})

	const configsButton = (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.25rem"
			height="3.25rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={99}
			color="dark"
			sx={{ cursor: "pointer" }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize="small" color="inherit">
				settings
			</Icon>
		</MDBox>
	)

	return direction === "rtl" ? (
		<CacheProvider value={rtlCache}>
			<ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
				<CssBaseline />
				{layout === "dashboard" && (
					<>
						<Sidenav
							color={sidenavColor}
							brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
							brandName="광림교회 청년부"
							routes={routes}
							onMouseEnter={handleOnMouseEnter}
							onMouseLeave={handleOnMouseLeave}
						/>
						{/* <Configurator />
						{configsButton} */}
					</>
				)}
				{layout === "vr" && <Configurator />}
				<Routes>
					{getRoutes(routes)}
					<Route path="*" element={<Navigate to="/dashboard" />} />
				</Routes>
			</ThemeProvider>
		</CacheProvider>
	) : (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			{layout === "dashboard" && (
				<>
					<Sidenav
						color={sidenavColor}
						brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
						brandName="광림교회 청년부"
						routes={routes}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
					{/* <Configurator />
					{configsButton} */}
				</>
			)}
			{layout === "vr" && <Configurator />}
			<Routes>
				{getRoutes(routes)}
				<Route
					path={ROUTE_LOGIN}
					element={<>{user.id === null ? <SignIn /> : <Navigate to={ROUTE_ATEENDANCE} />}</>}
				/>
				<Route path="*" element={<Navigate to={ROUTE_ATEENDANCE} />} />
			</Routes>
		</ThemeProvider>
	)
}
