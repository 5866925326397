import MDBox from "components/MDBox"
import OverlayProgress from "components/OverlayProgress"
import { DATE_FORMAT } from "constant"
import { format } from "date-fns"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import Footer from "layouts/authentication/components/Footer"
import ParishFilter from "layouts/common/ParishFilter"
import { debounce, isEmpty, omitBy } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { adminApi } from "services/modules"
import { getNearestSunday } from "util/date"

import { AttendanceList, AttendanceStatics } from "./components"

const AttendanceScreen = () => {
	const [loading, setLoading] = useState(false)

	const form = useForm({
		defaultValues: {
			date: getNearestSunday(),
			parish: "",
			group: "",
		},
	})
	const { watch, control, resetField } = form
	const { date: watchedDate, parish: watchedParish, group: watchedGroup } = watch()

	const weeklyStr = useMemo(
		() => (watchedDate ? format(watchedDate, DATE_FORMAT) : ""),
		[watchedDate]
	)
	const memoizedData = useMemo(() => {
		const data = {
			weekly_str: weeklyStr,
			parish_id: watchedParish,
			group_id: watchedGroup,
		}

		return omitBy(data, isEmpty)
	}, [watchedDate, watchedParish, watchedGroup])

	const [
		trigger,
		{ data: { data: { total, total_attended, attendance, total_unchecked } = {} } = {}, isSuccess },
	] = adminApi.useLazyGetAttendanceListQuery()

	const fetchData = useCallback(async () => {
		setLoading(true)
		await trigger(memoizedData)
		setLoading(false)
	}, [trigger, memoizedData])

	useEffect(() => {
		const debouncedFetchData = debounce(fetchData, 250)
		debouncedFetchData()

		return () => {
			debouncedFetchData.cancel()
		}
	}, [fetchData])

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<FormProvider {...form}>
				<MDBox pb={3}>
					<ParishFilter control={control} watch={watch} resetField={resetField} />
					{loading ? (
						<OverlayProgress />
					) : (
						isSuccess && (
							<>
								<AttendanceStatics
									total={total}
									attended={total_attended}
									unchecked={total_unchecked}
								/>

								<AttendanceList data={attendance} watchedDate={watchedDate} />
							</>
						)
					)}
				</MDBox>
			</FormProvider>
			{!loading && isSuccess && <Footer />}
		</DashboardLayout>
	)
}

export default AttendanceScreen
