const ADMIN_API = "/admin"
export const API_ADMIN_ATTENDANCE = `${ADMIN_API}/attendance` // 출석관리
export const API_ADMIN_HIERARCHY = `${ADMIN_API}/hierarchy` // 교구/속회 관계도

export const API_ADMIN_UPDATE_USER = `${ADMIN_API}/update_user` //프로필 수정

export const API_ADMIN_PRAYERS = `${ADMIN_API}/prayers` // 기도제목 가져오기

export const API_ADMIN_USER = `${ADMIN_API}/user` // 유저 정보 가져오기

export const API_ATTENDANCE_UNCHECKED = `${ADMIN_API}/attendance_unchecked` // 출석체크 안 한 속회 가져오기
