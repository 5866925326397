/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Link from "@mui/material/Link"
// @mui material components
import MenuItem from "@mui/material/MenuItem"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
// custom styles for the NotificationItem
import menuItem from "examples/Items/NotificationItem/styles"
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"
import { forwardRef } from "react"

const NotificationItem = forwardRef(({ icon, title, ...rest }, ref) => (
	<MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
		<MDBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
			<MDTypography variant="body1" color="secondary" lineHeight={0.75}>
				{icon}
			</MDTypography>
			<MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
				{title}
			</MDTypography>
		</MDBox>
	</MenuItem>
))

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
	icon: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
}

export default NotificationItem
