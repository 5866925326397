import { Grid } from "@mui/material"
import MDBox from "components/MDBox"
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard"
import PropTypes from "prop-types"

const AttendanceStatics = ({ total, attended, unchecked }) => {
	return (
		<Grid container spacing={3} display="flex" justifyContent="center" pt={2}>
			<Grid item xs={12} sm={6} lg={3}>
				<MDBox mb={1.5}>
					<ComplexStatisticsCard color="info" icon="groups" title="총 인원" count={total} />
				</MDBox>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<MDBox mb={1.5}>
					<ComplexStatisticsCard
						icon="chair"
						color="success"
						title="이번 주 출석 인원"
						count={attended}
						percentage={{
							color: "info",
							amount: `${Math.round((attended / total) * 100)}%`,
							label: "출석률",
						}}
					/>
				</MDBox>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<MDBox mb={1.5}>
					<ComplexStatisticsCard
						color="error"
						icon="chair"
						title="이번 주 결석 인원"
						count={total - attended - unchecked}
					/>
				</MDBox>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<MDBox mb={1.5}>
					<ComplexStatisticsCard
						color="dark"
						icon="chair"
						title="출석 미확인 인원"
						count={unchecked}
						isUnchecked
					/>
				</MDBox>
			</Grid>
		</Grid>
	)
}

export default AttendanceStatics

AttendanceStatics.propTypes = {
	total: PropTypes.number,
	attended: PropTypes.number,
	unchecked: PropTypes.number,
	attendance: PropTypes.array,
}
