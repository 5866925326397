export const getNearestSunday = () => {
	const today = new Date()
	const day = today.getDay()
	if (day === 0) return today

	const diff = today.getDate() - day
	return new Date(today.setDate(diff))
}

export const getPreviousDay = (date, days) => {
	const result = new Date(date)
	result.setDate(result.getDate() - days)
	return result
}
