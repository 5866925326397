/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
// @mui material components
import Icon from "@mui/material/Icon"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import PropTypes from "prop-types"
// react-router-dom components
import { Link } from "react-router-dom"

function DefaultNavbarLink({ icon, name, route, light }) {
	return (
		<MDBox
			component={Link}
			to={route}
			mx={1}
			p={1}
			display="flex"
			alignItems="center"
			sx={{ cursor: "pointer", userSelect: "none" }}
		>
			<Icon
				sx={{
					color: ({ palette: { white, secondary } }) => (light ? white.main : secondary.main),
					verticalAlign: "middle",
				}}
			>
				{icon}
			</Icon>
			<MDTypography
				variant="button"
				fontWeight="regular"
				color={light ? "white" : "dark"}
				textTransform="capitalize"
				sx={{ width: "100%", lineHeight: 0 }}
			>
				&nbsp;{name}
			</MDTypography>
		</MDBox>
	)
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
	icon: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	route: PropTypes.string.isRequired,
	light: PropTypes.bool.isRequired,
}

export default DefaultNavbarLink
