import { createApi } from "@reduxjs/toolkit/query/react"

import customAxios from "./customAxios"
import * as Tags from "./tags"

export const api = (reducerPath, ...options) =>
	createApi({
		reducerPath,
		baseQuery: async (args) => {
			try {
				const { data } = await customAxios(args)

				return { data }
			} catch (error) {
				return { error }
			}
		},
		tagTypes: Object.values(Tags),
		endpoints: () => ({}),
		...options,
	})

export const commonApi = api("common")
