import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import * as servicesModules from "services/modules"
import appVersionReducer from "stores/slice/appVersion"
import snackbarReducer from "stores/slice/snackbar"

import userReducer from "./slice/user"
import { APP_VERSION_SLICE, SNACKBAR_SLICE, USER_SLICE } from "./sliceKeys"

const persistConfig = {
	key: "root",
	storage,
	whitelist: [USER_SLICE, APP_VERSION_SLICE],
}

const services = Object.values(servicesModules)

const persistedUserReducer = persistReducer({ key: USER_SLICE, storage }, userReducer)
const persistedAppVersionReducer = persistReducer(
	{ key: APP_VERSION_SLICE, storage },
	appVersionReducer
)

const reducers = combineReducers({
	[USER_SLICE]: persistedUserReducer,
	[SNACKBAR_SLICE]: snackbarReducer,
	[APP_VERSION_SLICE]: persistedAppVersionReducer,
	...services.reduce(
		(init, module) => ({
			...init,
			[module.reducerPath]: module.reducer,
		}),
		{}
	),
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (defaultMiddleware) =>
		defaultMiddleware({
			serializableCheck: {
				ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(services.map((module) => module.middleware)),
})

const persistor = persistStore(store)

export default { store, persistor }
