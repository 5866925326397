import { ROUTE_ATEENDANCE, ROUTE_PRAYS } from "urls"

const SCREEN_NAMES = {
	[ROUTE_ATEENDANCE]: "출석 관리",
	[ROUTE_PRAYS]: "기도 목록",
}

export const getScreenName = (route) => {
	if (!route) return ""

	const parsedRoute = route.split("/").slice(1)
	const screenName =
		SCREEN_NAMES[Object.keys(SCREEN_NAMES).find((key) => key.includes(parsedRoute[0]))]
	return screenName ? screenName : route
}
