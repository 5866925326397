import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import DatePicker from "components/DatePicker"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { adminApi } from "services/modules"
import { useUser } from "stores/slice/user"

const ParishFilter = ({ watch, control, resetField }) => {
	const [groups, setGroups] = useState([])

	const user = useUser()

	const parishValue = watch("parish")

	const { data } = adminApi.useGetParishesHirarchyQuery({ id: user.id })
	const parishes = data?.data || []

	useEffect(() => {
		if (parishValue) {
			resetField("group")
			const selectedParishGroups = parishes
				.filter((parish) => parish.parish_id === parishValue)
				.pop().groups
			setGroups(selectedParishGroups)
		}
	}, [parishValue])

	return (
		<MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={3}>
					<FormControl fullWidth>
						<DatePicker name="date" label="날짜를 선택해 주세요" control={control} />
					</FormControl>
				</Grid>
				<Grid item xs={12} md={3}>
					<FormControl fullWidth>
						<InputLabel id="parish-label">교구를 선택해주세요</InputLabel>
						<Controller
							name="parish"
							control={control}
							render={({ field }) => (
								<Select
									labelId="parish-label"
									id="parish"
									label="교구를 선택해주세요"
									{...field}
									value={field.value}
									onChange={({ target }) => field.onChange(target.value)}
								>
									{parishes.map(({ parish_id, parish_name, parish_leader_name }) => (
										<MenuItem key={parish_id} value={parish_id}>
											{`${parish_name} ${parish_leader_name}`}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={3}>
					<FormControl fullWidth>
						<InputLabel id="class-label">속회를 선택해주세요</InputLabel>
						<Controller
							name="group"
							control={control}
							render={({ field }) => (
								<Select
									labelId="class-label"
									id="class"
									label="속회를 선택해주세요"
									{...field}
									value={field.value}
									onChange={({ target }) => field.onChange(target.value)}
								>
									{groups.map(({ group_id, group_leader_id, group_leader_name }) => (
										<MenuItem key={group_id} value={group_id}>
											{group_leader_name}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={2} xl={1.5} display="flex" alignItems="center">
					<MDButton
						variant="contained"
						color="info"
						fullWidth
						onClick={() => {
							resetField("parish")
							resetField("group")
						}}
					>
						초기화
					</MDButton>
				</Grid>
			</Grid>
		</MDBox>
	)
}

export default ParishFilter

ParishFilter.propTypes = {
	control: PropTypes.object.isRequired,
	watch: PropTypes.func.isRequired,
	resetField: PropTypes.func.isRequired,
}
