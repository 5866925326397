/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors"
import typography from "assets/theme/base/typography"

// Material Dashboard 2 React helper functions
// import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography
const { text } = colors

const dialogContentText = {
	styleOverrides: {
		root: {
			fontSize: size.md,
			color: text.main,
		},
	},
}

export default dialogContentText
