/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
// @mui material components
import { Grid } from "@mui/material"
import Container from "@mui/material/Container"
import Icon from "@mui/material/Icon"
import Link from "@mui/material/Link"
// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import PropTypes from "prop-types"

function Footer({ light }) {
	const { size } = typography

	return (
		<Grid
			container
			position="absolute"
			width="100%"
			bottom={0}
			left={0}
			py={{ xs: 1, lg: 4 }}
			display="flex"
			flexDirection={{ xs: "column", lg: "row" }}
			justifyContent="space-between"
			alignItems="center"
		>
			<Grid
				item
				xs={12}
				lg={4}
				display="flex"
				justifyContent="center"
				alignItems="center"
				flexWrap="wrap"
				color={light ? "white" : "text"}
				fontSize={size.sm}
			>
				&copy; {new Date().getFullYear()}, made with
				<MDBox fontSize={size.md} color={light ? "white" : "dark"} mb={-0.5} mx={0.25}>
					<Icon color="inherit" fontSize="inherit">
						favorite
					</Icon>
				</MDBox>
				by
				<Link href="http://klmc.church/index.do" target="_blank">
					<MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
						&nbsp;광림교회 청년부&nbsp;
					</MDTypography>
				</Link>
			</Grid>
			<Grid
				item
				xs={12}
				lg={4}
				sx={({ breakpoints }) => ({
					display: "flex",
					flexWrap: "wrap",
					alignItems: "center",
					justifyContent: "center",
					listStyle: "none",
					mt: { xs: 1, lg: 0 },
					mb: 0,
					p: 0,

					[breakpoints.up("lg")]: {
						mt: 0,
					},
				})}
			>
				<MDBox pr={2} lineHeight={1}>
					<Link href="https://www.youtube.com/@kwanglimlfc" target="_blank">
						<MDTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
							Youtube
						</MDTypography>
					</Link>
				</MDBox>
			</Grid>
			{/* </Container> */}
		</Grid>
	)
}

// Setting default props for the Footer
Footer.defaultProps = {
	light: false,
}

// Typechecking props for the Footer
Footer.propTypes = {
	light: PropTypes.bool,
}

export default Footer
