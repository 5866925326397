/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React base styles
import borders from "assets/theme/base/borders"
import boxShadows from "assets/theme/base/boxShadows"
import colors from "assets/theme/base/colors"

const { white } = colors
const { md } = boxShadows
const { borderRadius } = borders

const tableContainer = {
	styleOverrides: {
		root: {
			backgroundColor: white.main,
			boxShadow: md,
			borderRadius: borderRadius.xl,
		},
	},
}

export default tableContainer
