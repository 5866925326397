import { isEmpty, omitBy } from "lodash"
import { api } from "services/api"
import {
	API_ADMIN_ATTENDANCE,
	API_ADMIN_HIERARCHY,
	API_ADMIN_PRAYERS,
	API_ADMIN_UPDATE_USER,
	API_ADMIN_USER,
	API_ATTENDANCE_UNCHECKED,
} from "services/endpoints"
import { TAG_ATTENDANCE_LIST, TAG_PARISHES, TAG_PRAYERS, TAG_USER } from "services/tags"

export default api("adminApi").injectEndpoints({
	endpoints: (build) => ({
		getParishesHirarchy: build.query({
			query({ id }) {
				return {
					url: API_ADMIN_HIERARCHY,
					params: { user_id: id },
				}
			},

			providesTags: [TAG_PARISHES],
		}),

		getAttendanceList: build.query({
			query(data) {
				const params = toQueryString(data)
				return {
					data,
					url: `${API_ADMIN_ATTENDANCE}?${params}`,
				}
			},

			providesTags: [TAG_ATTENDANCE_LIST],
		}),

		postAttendanceChangeProfile: build.mutation({
			query(data) {
				return {
					method: "PATCH",
					data,
					url: API_ADMIN_UPDATE_USER,
				}
			},

			invalidatesTags: [TAG_USER, TAG_ATTENDANCE_LIST],
		}),

		getAttendanceUser: build.query({
			query(data) {
				const params = toQueryString(data)
				return {
					method: "GET",
					url: `${API_ADMIN_USER}?${params}`,
				}
			},

			providesTags: [TAG_USER],
		}),

		getPrayers: build.query({
			query(data) {
				const params = toQueryString(data)
				return {
					data,
					url: `${API_ADMIN_PRAYERS}?${params}`,
				}
			},

			transformResponse: (response) => {
				const data = response?.data || {}

				const { groups } = data
				if (isEmpty(groups)) return {}

				return omitBy(groups, (group) => isEmpty(group?.prayer_lists))
			},

			providesTags: [TAG_PRAYERS],
		}),

		getAttendanceUnchecked: build.query({
			/**
			 * @param {{weekly_date: string; parish_id?: string;}} data
			 */
			query(data) {
				const params = toQueryString(data)
				return {
					url: `${API_ATTENDANCE_UNCHECKED}?${params}`,
				}
			},
		}),
	}),
})

//
// HELPERS
//
const toQueryString = (params) => {
	return Object.keys(params)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
		.join("&")
}
